.headerLogo {
    width: 150px !important;
    height: auto !important;
}

.headerProfileArea .MuiButtonBase-root {
    padding: 0 !important;
}

@media only screen and (max-width: 768px) {
    .headerLogo {
        width: 125px !important;
    }

    .headerProfileArea .MuiButtonBase-root p {
        display: none;
    }
}

@media screen and (max-width: 600px) {
    .helpTextArea {
        min-width: 400px !important;
    }
}

@media screen and (max-width: 480px) {
    .helpTextArea {
        min-width: 300px !important;
    }
}

@media screen and (max-width: 380px) {
    .helpTextArea {
        min-width: 240px !important;
    }
}

