/* For Circular Progressbar */

.CircularProgressbar-trail {
  stroke: transparent !important;
  stroke-width: 4px !important;
}

.CircularProgressbar-path {
  stroke-width: 4px !important;
}

.CircularProgressbar-Above .CircularProgressbar-path,
.CircularProgressbar-above .CircularProgressbar-path,
.CircularProgressbar-Over .CircularProgressbar-path,
.CircularProgressbar-over .CircularProgressbar-path {
  stroke: #0000ff !important;
}

.CircularProgressbar-Below .CircularProgressbar-path,
.CircularProgressbar-below .CircularProgressbar-path {
  stroke: #bd1a41 !important;
}

.CircularProgressbar-On .CircularProgressbar-path,
.CircularProgressbar-on .CircularProgressbar-path {
  stroke: #7cbd1a !important;
}

/* For Profile Avatar Background Svg */
.profileCard__avatarWrapper {
  width: 100%;
  margin-top: 1rem;
  /* background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjIwIiBoZWlnaHQ9IjE2NyIgdmlld0JveD0iMCAwIDIyMCAxNjciIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxjaXJjbGUgY3g9IjE4NiIgY3k9IjkiIHI9IjkiIGZpbGw9IiM3Q0JEMUEiLz4KPGNpcmNsZSBjeD0iMjExIiBjeT0iMTQxIiByPSI5IiBmaWxsPSIjNkIzQUZGIi8+CjxjaXJjbGUgY3g9IjcuNSIgY3k9IjY3LjUiIHI9IjcuNSIgZmlsbD0iIzQzMkJBNCIvPgo8Y2lyY2xlIGN4PSIzMy41IiBjeT0iMTU5LjUiIHI9IjcuNSIgZmlsbD0iI0ZBQ0U2QiIvPgo8L3N2Zz4K');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain; */
}

.profileCard__avatarWrapper > * {
  width: 136px;
  height: 136px;
}

/* For Learner Name & Info Modal Icon Wrapper */
.learnerNameInfoWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Info Icon */
.infoIcon {
  font-size: 20px !important;
  color: #fff;
  cursor: pointer;
  transition: color 0.3s linear;
}

.infoIcon:hover {
  color: rgba(255, 255, 255, 0.8);
}
