/* For All Extensions CSS Starts Here */
.pg-viewer-wrapper {
  overflow: auto !important;
}
/* For All Extensions CSS Ends Here */

/* .mp4 CSS Starts Here */
.photo-viewer-container,
.photo {
  width: 100% !important;
  height: auto !important;
}
/* .mp4 CSS Ends Here */

/* .xlxs CSS Starts Here */
.pg-driver-view .video-container video {
  max-width: 95vw !important;
  height: auto !important;
}

.pg-viewer .spreadsheet-viewer .react-grid-Container {
  min-width: calc(95vw - 80px) !important;
  width: auto !important;
}

.pg-viewer .spreadsheet-viewer .react-grid-Grid {
  min-height: calc(95vh - 80px) !important;
}

.pg-viewer .spreadsheet-viewer .react-grid-Canvas {
  width: 100% !important;
  min-height: calc(95vh - 132px) !important;
}
/* .xlxs CSS Ends Here */
