.subscription__subHeading {
  font-size: 16px !important;
  font-weight: 700 !important;
  margin: 10px 0 !important;
}

.subscription__subTitle {
  font-size: 14px !important;
  font-weight: 600 !important;
  margin: 0 !important;
}

.subscription__subTitle > span {
  font-size: 12px;
  font-weight: 500;
}

.bottom-part{
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #d9d9d9;
margin: 10px 2px 10px 2px !important;
}
.bottom-part-2{
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #d9d9d9;
margin: 10px 2px 10px 2px !important;
}

.dataGrid__wrapper {
  height: 280px;
  width: 100%;
}

.subscriptionModalContainer {
  min-width: 800px;
  /* min-height: 700px; */
  max-height: 95vh;
  max-width: 95vw;
  padding-bottom: 0 !important;
}

.subscriptionModalFooter {
  display: flex;
  flex-direction: row !important;
  align-items: center;
  justify-content: space-between;
}

.subscriptionModalFooter > * {
  width: 50% !important;
}

.subscription__btn {
  padding: 0 !important;
}

@media only screen and (max-width: 768px) {
  .subscriptionModalContainer {
    min-width: 95vw;
    /* min-height: 95vh; */
  }
}

@media only screen and (max-width: 600px) {
  .helpTextArea {
    min-width: 400px !important;
  }
}

@media only screen and (max-width: 480px) {
  .helpTextArea {
    min-width: 300px !important;
  }
}

@media only screen and (max-width: 380px) {
  .helpTextArea {
    min-width: 240px !important;
  }

  .subscriptionModalFooter {
    flex-direction: column !important;
  }

  .subscriptionModalFooter > * {
    width: 100% !important;
  }

  .subscription__btn {
    padding-top: 20px !important;
    width: 100%;
  }

  .subscription__btn > * {
    width: 100% !important;
  }
}
