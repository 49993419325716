.learnerInfoModalContainer {
  height: 815px;
  min-height: unset !important;
  max-height: 95vh !important;
  overflow: auto;
}

.learnerInfoModalContainer .editHoursModalSignUp__form {
  min-width: 100%;
  max-width: 100%;
  min-height: 100%;
  max-height: 100%;
  overflow: auto;
}

.learnerInfoModalContainer  .editHoursModalSignUp__table {
  min-width: unset;
}

/* Styles Below Are Used In Other Places Also */

.learnerProfileInfo > hr {
  margin: 20px 0;
}

.learnerProfileInfo__subHeading {
  font-size: 16px !important;
  font-weight: 700 !important;
}

.learnerProfileInfo__text {
  font-size: 14px !important;
  font-weight: 500 !important;
}

.learnerProfileInfo__text > span {
  font-size: 12px;
  color: #5335ca;
}

.learnerProfileInfo__group {
  margin: 5px 0;
}

.learnerProfileInfo__group:first-of-type {
  margin: 20px 0 5px 0;
}
