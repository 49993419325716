
.editHoursModalSignUp__table {
  width: 100%;
  min-width: 600px;
  /* overflow: auto; */
}
@media (max-width: 800px) {
  .editHoursModalSignUp__table {
    max-width: 800px;
    min-width: 300px;
  }
}
@media (max-width: 420px) {
  .editHoursModalSignUp__table {
    /* min-width: 100vw; */
    min-width: 300px;
  }
}

* {
  background-color: "#E5E5E5";
  font: "Poppins";
  font-family: "Poppins";
  background: "#E5E5E5";
}

body {
  background-color: "#E5E5E5";
  font-family: "Poppins";
  font: "Poppins";
  background: "#E5E5E5";
}

.root {
  background-color: "#E5E5E5";
}

.radioForm {
  width: 100%;
}

.radioForm .form-control {
  width: 90%;
}

.radioForm .form-control .radio-group .form-control-label {
  flex-direction: row-reverse;
  justify-content: space-between;
}

/* width */
::-webkit-scrollbar {
  /* height of horizontal scrollbar */
  height: 6px;
  /* width of vertical scrollbar */
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px lightgray;
  border-radius: 7px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: lightgray;
  border-radius: 7px;
}

.pd-bottom-40 {
  padding-bottom: 40px;
}

.mar-top-20 {
  margin-top: 20px;
}

/* For All Modal Headers */
.modalContainer {
  /* position: relative !important; */
  padding-bottom: 0 !important;
  overflow: auto !important;
  outline: none !important;
}

.modalHeader {
  /* min-width: 100vw; */
  position: sticky;
  top: -32px;
  padding-top: 20px;
  padding-bottom: 15px;
  background-color: #fff;
  z-index: 10;
  margin-top: -20px;
  border-bottom: 1px solid #a2a2a2;
}

.modalHeader.noBorder {
  border-bottom: none;
}

.drawerHeader {
  position: sticky;
  top: -20px;
  padding-top: 20px;
  padding-bottom: 15px;
  background-color: #fff;
  z-index: 10;
  margin-top: -20px;
  border-bottom: 1px solid #a2a2a2;
}

.drawerHeader.noBorder {
  border-bottom: none;
}

@media only screen and (max-width: 768px) {
  .modalContainer {
    max-height: 95vh !important;
  }
}

@media only screen and (max-width: 430px) {
  .modalFooter {
    /* width: 100%; */
  }
}

.modalFooter {
  /* width: fit-content; */
  position: sticky;
  bottom: 0;
  background-color: #fff;
  padding: 32px 0;
  z-index: 20;
}

.drawerFooter {
  position: sticky;
  bottom: 0;
  background-color: #fff;
  padding: 20px 0;
  z-index: 20;
}

.activeIndicator {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #ddd;
  border: 1px solid #aaa;
  margin: 0 auto;
}

.activeIndicator.active {
  background-color: #5335ca;
  border: 1px solid #5335ca;
}
