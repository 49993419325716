/* For LearnerList & FacultyList */
.learnerListWrapper {
    display: flex;
    flex-direction: column !important;
    height: 100%;
}

.learnerListWrapper>h3 {
    flex-grow: 0 !important;
}

.learnerListWrapper>* {
    flex-grow: 1 !important;
}

/* For All Material Card Components */
.card-content-manager-page {
    padding: 18px !important;
  }

  .totalPerformanceBoxManagerPage > * {
    padding: 8px 2px !important;
    margin: 5px 5px !important;
    min-height: 100% !important;
    text-align: center !important;
    border: 1px solid #eee !important;
    border-radius: 5px !important;
    max-width: calc(25% - 10px) !important;
  }

@media screen and (max-width: 505px) {
    .listHeader {
        text-align: center;
        flex-direction: column;
    }




    /* .totalPerformance {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
      } */
      
      .totalPerformanceBoxManagerPage {
        justify-content: space-between;
      }
      
      .totalPerformanceBoxManagerPage > * {
        padding: 8px !important;
        margin: 5px 0 !important;
        min-height: 100% !important;
        text-align: center !important;
        border: 1px solid #eee !important;
        border-radius: 5px !important;
        max-width: calc(25% - 10px) !important;
      }
      
      
      @media only screen and (max-width: 900px) {
        .totalPerformanceBoxManagerPage > * {
          flex-direction: column !important;
          flex: 1 1 100% !important;
          max-width: 100% !important;
          margin: 5px 0 !important;
        }
      }
      
      /* @media only screen and (max-width: 768px) {
        .totalPerformanceBoxManagerPage > * {
            flex-direction: column !important;
            flex: 1 1 100% !important;
            max-width: 100% !important;
            margin: 5px 0 !important;
          }
      } */

    /* .listCardTop {
        display: flex !important;
        flex-direction: column !important;
        justify-content: center !important;
        align-items: center !important;
    }

    .listCardTop>div {
        width: 100%;
    }

    .listCardTop__buttons {
        padding-top: 10px !important;
        width: 100%;
        display: flex !important;
        flex-direction: row !important;
        justify-content: space-between !important;
        align-items: center !important;
    } */
}

/* .totalPerformanceBox {
    justify-content: space-between;
} */

/* Total Performance Box */
/* .totalPerformanceBox>* {
    padding: 8px !important;
    margin: 5px 0 !important;
    min-height: 100% !important;
    text-align: center !important;
    border: 1px solid #eee !important;
    border-radius: 5px !important;
    max-width: calc(25% - 10px) !important;
}

@media only screen and (max-width: 900px) {
    .totalPerformanceBox>* {
        flex: 1 1 100% !important;
        max-width: 100% !important;
        margin: 5px 0 !important;
    }
} */

/* For Statistics Container */
@media only screen and (max-width: 900px) {
    .orderLast {
        order: -1 !important;
    }
}

@media screen and (max-width: 774px) {
    .listHeader {
        text-align: center;
        flex-direction: column;
    }




    /* .totalPerformance {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
      } */
      
      .totalPerformanceBoxManagerPage {
        justify-content: space-between;
      }
      
      .totalPerformanceBoxManagerPage > * {
        padding: 8px !important;
        margin: 5px 0 !important;
        min-height: 100% !important;
        text-align: center !important;
        border: 1px solid #eee !important;
        border-radius: 5px !important;
        max-width: calc(25% - 10px) !important;
      }
      
      
      @media only screen and (max-width: 900px) {
        .totalPerformanceBoxManagerPage > * {
          flex-direction: column !important;
          flex: 1 1 100% !important;
          max-width: 100% !important;
          margin: 5px 0 !important;
        }
      }
}