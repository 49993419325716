@media screen and (max-width: 600px) {
    .helpTextArea {
        min-width: 400px !important;
    }
}

@media screen and (max-width: 480px) {
    .helpTextArea {
        min-width: 300px !important;
    }
}

@media screen and (max-width: 380px) {
    .helpTextArea {
        min-width: 240px !important;
    }
}