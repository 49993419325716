.profileImage {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
}

.profileImageWrapper {
  /* cursor: pointer; */
  border-radius: 50%;
  position: relative;
  z-index: 1;
}

.profileImageWrapper > span {
  position: relative;
  display: inline-block;
  /* cursor: pointer; */
  border-radius: 50%;
  overflow: hidden;
}

.editIcon {
  position: absolute;
  top: 2px;
  right: 2px;
  background: #eee;
  border: 1px solid transparent;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  color: #5335ca;
  cursor: pointer;
  z-index: 10;
}

.deleteIcon {
  position: absolute;
  top: 27px;
  right: -11.5px;
  background: #eee;
  border: 1px solid transparent;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  color: #5335ca;
  cursor: pointer;
  z-index: 10;
}
