.editHoursModalSignUp__table {
  width: 100%;
  min-width: 600px;
}
@media (max-width: 800px) {
  .editHoursModalSignUp__table {
    max-width: 800px;
    min-width: 300px;
  }
}
@media (max-width: 420px) {
  .editHoursModalSignUp__table {
    /* min-width: 100vw; */
    min-width: 300px;
  }
}

.editHoursModalSignUp__table--wrapper {
  /* max-height: 500px;
  overflow: auto; */
}

.editHoursModalSignUp__table--row {
  display: grid;
  grid-template-columns: 0.4fr 0.3fr 0.3fr;
  align-items: center;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.8);
  text-align: center;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.23);
  background-color: transparent;
  text-transform: uppercase;
}

.editHoursModalSignUp__table--row:not(:last-of-type) {
  margin-bottom: 10px;
}

@media (max-width: 600px) {
  .editHoursModalSignUp__table--row {
    font-size: 11px;
  }
}
@media (max-width: 420px) {
  .editHoursModalSignUp__table--row {
    font-size: 8px;
  }
  .editHoursModalSignUp__table--row:not(:last-of-type) {
    margin-bottom: 5px;
  }
}

@media (max-width: 340px) {
  .editHoursModalSignUp__table--row {
    font-size: 7px;
  }
}

.editHoursModalSignUp__table--row-heading {
  color: rgba(83, 53, 202, 1);
  font-size: 12px;
  font-weight: bold;
  text-transform: capitalize;
  border: none;
  border-radius: 0;
  background-color: transparent;
}

@media (max-width: 420px) {
  .editHoursModalSignUp__table--row-heading {
    font-size: 10px;
  }
}

.editHoursModalSignUp__table--row p {
  color: rgba(0, 0, 0, 0.8);
  font-size: inherit;
  line-height: 1;
}

.editHoursModalSignUp__table--input {
  width: 95%;
  outline: none;
  text-align: center;
  color: rgba(0, 0, 0, 0.8);
  font-weight: inherit;
  border-radius: 5px;
  border: 1px solid rgba(83, 53, 202, 1);
}

@media (max-width: 430px) {
  .editHoursModalSignUp__table--input {
    font-size: 11px;
    width: 35px;
  }
}

@media (max-width: 340px) {
  .editHoursModalSignUp__table--input {
    font-size: 11px;
    width: 35px;
  }
}

.editHoursModalSignUp__table--input::-webkit-outer-spin-button,
.editHoursModalSignUp__table--input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.editHoursModalSignUp__table--input[type="number"] {
  -moz-appearance: textfield;
}

.editHoursModalSignUp__footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  /* padding: 10px 0; */
}

.editHoursModalSignUp__footer > *:not(:last-child) {
  margin-right: 10px;
}

/* Main Section */
@media screen and (max-width: 900px) {
  .mainSection {
    flex-direction: column-reverse !important;
  }
}

/* For LearnerList & FacultyList */
.learnerListCoachs > a {
  color: rgba(0, 0, 0, 0.87) !important;
}

.learnerListCoachs > a {
  color: #5335ca !important;
}

.learnerListCoachs,
.learnerListCoachs > a {
  text-decoration: none !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.listCardTop__buttons {
  font-size: 20px;
  line-height: 1;
}

.listCardTop__buttons button {
  color: #5335ca !important;
}

.MuiBadge-root .MuiBadge-badge {
  background-color: #5335ca !important;
}

@media screen and (max-width: 505px) {
  .listHeader {
    text-align: center;
    flex-direction: column;
  }

  .listCardTop {
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
  }

  .listCardTop > div {
    width: 100%;
  }

  .listCardTop__buttons {
    padding-top: 10px !important;
    width: 100%;
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-between !important;
    align-items: center !important;
  }
}

/* Cohort Report */

.cohortInput > * {
  width: 100%;
}

.cohortButton {
  padding-top: 10px;
}

@media only screen and (max-width: 600px) {
  .cohortButton > button {
    width: 100%;
  }
}

/* For Full Width Inputs */
.fullWidthInput {
  width: 100% !important;
}

.fullWidthInput > * {
  width: 100% !important;
}

/* For Full Width Inputs In Mobile */
@media only screen and (max-width: 900px) {
  /* .fullWidthInput  {
    width: 100% !important;
  }

  .fullWidthInput  > * {
    width: 100% !important;
  } */

  .modalContainer {
    width: 95vw !important;
  }
}

/* For Faculty & Learner Lists Filter Popups */
@media only screen and (max-width: 769px) {
  .MuiGridPanel-root .MuiGridPanel-paper {
    min-width: 100% !important;
  }

  .MuiGridFilterForm-root {
    flex-direction: column !important;
    padding: 20px !important;
  }

  .MuiGridFilterForm-root > * {
    width: 100% !important;
  }

  .MuiGridFilterForm-root > *:not(:last-child) {
    margin-bottom: 15px !important;
  }

  .MuiGridFilterForm-closeIcon {
    align-self: flex-end;
    width: auto !important;
    margin: 0 !important;
  }

  .MuiPopover-paper {
    box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.2) !important;
  }
  .buttonFix {
    margin-right: 0px !important;
    margin-bottom: 10px !important;
    min-width: 100% !important;
  }
}

/* Total Performance Box */
.totalPerformance {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.totalPerformanceBox {
  justify-content: space-between;
}

.totalPerformanceBox > * {
  padding: 8px 2px !important;
  margin: 5px 0 !important;
  min-height: 100% !important;
  text-align: center !important;
  border: 1px solid #eee !important;
  border-radius: 5px !important;
  max-width: calc(20% - 10px) !important;
}
.totalPerformanceBoxAdminPage > * {
  display: flex !important;
  flex-direction: column !important;
  padding: 8px 2px !important;
  margin: 5px 5px !important;
  min-height: 100% !important;
  text-align: center !important;
  border: 1px solid #eee !important;
  border-radius: 5px !important;
  max-width: calc(20% - 10px) !important;
}

@media only screen and (max-width: 900px) {
  .totalPerformanceBox > * {
    flex-direction: column !important;
    flex: 1 1 100% !important;
    max-width: 100% !important;
    margin: 5px 0 !important;
  }
}
@media only screen and (max-width: 900px) {
  .learnerPerformanceBox > * {
    flex-direction: column !important;
    flex: 1 1 100% !important;
    max-width: 100% !important;
    margin: 5px 0 !important;
  }
}

@media only screen and (max-width: 505px) {
  .totalPerformance {
    flex-direction: column !important;
    text-align: center;
  }

  .totalPerformance > * {
    flex: 1 1 100%;
    width: 100%;
  }
}

/* For All Material Card Components */
.card-content {
  padding: 22px !important;
}

/* To Capitalize The Material Autocomplete */
.capitalizeAutoComplete input {
  text-transform: capitalize !important;
}

/* Admin Subscription Container */
.adminSubscriptionBox .MuiCardActions-root {
  padding-bottom: 0px !important;
}
/* .adminSubscriptionBox{
  padding: 30px;
} */

/* Material Switch Label */
/* .materialSwitchLabel {
  margin-bottom: 20px;
} */

/* Material Switch Button */
.materialSwitchBtn > .PrivateSwitchBase-root.Mui-checked {
  color: #5335ca !important;
}

.materialSwitchBtn > .PrivateSwitchBase-root.Mui-checked + .MuiSwitch-track {
  background-color: rgba(83, 53, 202, 0.5);
}

/* Edit Learner Modal Footer */
.editLearnerModal__footer,
.editFacultyModal__footer {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  /* 
  position: sticky;
  bottom: 0;
  background-color: #fff;
  padding: 32px 0;
  z-index: 20; */
}

.labelStyles {
  padding-left: 5px !important;
}

.materialRolesAutocomplete .MuiAutocomplete-listbox li {
  text-transform: capitalize !important;
}
